<template>
  <div style="min-height: 700px">
    <!--    <TitleCard title="通讯录" style="max-width: 97%; margin: 10px auto">-->
    <!--      <template v-slot:subTitle>-->
    <!--        <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">-->
    <!--          <li class="breadcrumb-item text-muted">Member Center</li>-->
    <!--        </ul>-->
    <!--      </template>-->
    <!--    </TitleCard>-->
    <div class="post d-flex flex-column-fluid" id="kt_post">
      <div style="max-width: 98%; flex-direction: row !important; display: flex" id="kt_content_container"
           class="container">
        <!-- 搜索开始 -->
        <div class="flex-column flex-lg-row-auto w-100 w-lg-250px w-xxl-325px mb-8 mb-lg-0 me-lg-9 me-5">
          <form action="#" id="category_form1">
            <div class="card">
              <div class="card-body" style="padding: 1.2rem 1rem">
                <div class="mb-5">
                  <label class="fs-6 form-label fw-bolder text-dark">企业名称</label>
                  <input id="sPhone" type="text" v-model="globalListParams.enterpriseName"
                         class="form-control form-control form-control-solid" placeholder="请输入企业名称"/>
                </div>
                <div class="mb-5">
                  <label class="fs-6 form-label fw-bolder text-dark">商品名称</label>
                  <input id="sPhone" type="text" v-model="globalListParams.goodsName"
                         class="form-control form-control form-control-solid" placeholder="请输入商品名称"/>
                </div>
                <div class="mb-5">
                  <label class="fs-6 form-label fw-bolder text-dark">商品一级分类</label>
                  <select class="form-select form-select-solid" v-model="queryParam.categoryId1" data-placeholder="全部"
                          @change="changeCategoryId1">
                    <option value="null">全部</option>
                    <option v-for="(item, index) in categoryOne" :key="index" :value="item.categoryId">
                      {{ item.fullName }}
                    </option>
                  </select>
                </div>
                <div class="mb-5">
                  <label class="fs-6 form-label fw-bolder text-dark">商品二级分类</label>
                  <select class="form-select form-select-solid" v-model="queryParam.categoryId2"
                          data-placeholder="全部">
                    <option value="null">全部</option>
                    <option v-for="(item, index) in categoryTwo" :key="index" :value="item.categoryId">
                      {{ item.fullName }}
                    </option>
                  </select>
                </div>
                <div class="d-flex align-items-center justify-content-end">
                  <a @click="shaixuan" href="javascript:void(0);"
                     class="btn btn-primary">筛选</a>&nbsp;&nbsp;&nbsp;
                  <a @click="resetQuery" href="javascript:void(0);"
                     class="btn text-hover-primary bg-hover-light btn-color-gray-400 me-3">重置</a>
                </div>
              </div>
            </div>
          </form>
        </div>
        <!-- 列表开始 -->
        <div class="card mb-5 mb-xl-8"
             style="margin-left: 3px; width: 100%;min-height: 80vh;background:none;min-height:90vh"
             element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
             element-loading-background="rgba(0, 0, 0, 0.8)" v-loading="loading">
          <el-empty description="暂无数据"
                    v-if="rows[0].member.length === 0 && rows[1].member.length === 0 && rows[2].member.length === 0 && rows[3].member.length === 0"></el-empty>

          <div v-for="(i,index) in rows" :key="index">
            <template v-if="(isShowGoods===false && i.member.length>0) || (isShowGoods && i.member.length>0 && i.member[0].goodsList && i.member[0].goodsList.row && i.member[0].goodsList.row.length>0)">
              <TitleCard :title="i.level" style="max-width: 97%; margin: 10px"
                         v-if="i.member.length !==0"></TitleCard>
              <div  class="card-body" style="padding-top: 0px">
                <div class="row g-6 mb-5 g-xl-9 mb-xl-1">
                  <div :class="{'col-md-12': isShowGoods,'col-md-3':!isShowGoods,'d-flex':true}"
                       v-for="(memberItem,memberIndex) in i.member" :key="memberIndex">
                    <div :class="{'col-md-3':isShowGoods,'card':true}" :style="`min-width: 270.5px;${isShowGoods?'':'width: 100%'}`">
                      <div class="card-body d-flex flex-center flex-column py-9 px-5">
                        <div v-if="memberItem.isNew" class="rightTopTag">
                          <span style="margin-left: 15px">新</span>
                        </div>
                        <div class="symbol symbol-65px symbol-circle mb-5">
                          <img :src="memberItem.logo" alt="image"/>
                        </div>
                        <el-tooltip :content = "memberItem.name">
                          <a href="#" class="fs-4 text-gray-800 text-hover-primary fw-bold mb-0"
                             style="width: 180px; white-space: nowrap; overflow: hidden;text-overflow: ellipsis;text-align: center " >{{ memberItem.name }}</a>
                        </el-tooltip>
                        <!--end::Name-->
                        <!--begin::Position-->
                        <div class="fw-semibold text-gray-400 mb-6" :title="'主营品牌：'+memberItem.category" style="overflow: hidden;
    white-space: nowrap;width: 200px;text-overflow: ellipsis;">主营品牌：{{ memberItem.category }}
                        </div>
                        <!--end::Position-->
                        <!--begin::Info-->
                        <div class="d-flex flex-center flex-wrap mb-5">
                          <!--begin::Stats-->
                          <div v-if="memberItem.goodsList.list ===null || memberItem.goodsList.list.length === 0"
                               class="border border-dashed rounded min-w-90px py-3 px-4 mx-2 mb-3">
                            <div class="fs-6 fw-bold text-gray-700 text-center">0</div>
                            <div class="fw-semibold text-gray-400 text-center">商品数量</div>
                          </div>
                          <template v-else>
                            <div v-for="(i,a) in memberItem.goodsList.list"
                                 :key="a" class="border border-dashed rounded min-w-90px py-3 px-4 mx-2 mb-3">
                              <div class="fs-6 fw-bold text-gray-700 text-center">{{ i.count }}</div>
                              <div class="fw-semibold text-gray-400 text-center">{{ i.title }}数量</div>
                            </div>
                          </template>
                        </div>
                        <button class="btn btn-sm btn-primary btn-flex btn-center" data-kt-follow-btn="true"
                                @click="openInfo(memberItem)">
                          <span class="indicator-label">查看详情</span>
                        </button>
                      </div>

                    </div>
                    <div class="card col-md-9" style="margin-left: 10px" v-show="isShowGoods">
                      <vip-goods @openBuyOneFromGoods="openBuyOneFromGoods" :type="typeIndex" :globalListParams="globalListParams" ref="vipGoodss" :queryParam="queryParam" @openBuyManyFromGoods="openBuyManyFromGoods" @openGoodInfo="openGoodInfo" :staffId="memberItem.staffId"></vip-goods>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <div style="display: flex;justify-content: center" v-if="index !== 0">
              <el-pagination v-if="(index==1 && isShowGoods===false && i.member.length>0) || (index==1 && isShowGoods && i.member.length>0 && i.member[0].goodsList && i.member[0].goodsList.row && i.member[0].goodsList.row.length>0)"
                             @current-change="vicePresidentHandleCurrentChange"
                             :current-page="listVicePresidentParams.current"
                             layout="total, prev, pager, next, jumper"
                             :page-size="8"
                             :total="vicePresidentTotal">
              </el-pagination>
              <el-pagination v-if="(index==2 && isShowGoods===false && i.member.length>0) || (index==2 && isShowGoods && i.member.length>0 && i.member[0].goodsList && i.member[0].goodsList.row && i.member[0].goodsList.row.length>0)"
                             @current-change="directorHandleCurrentChange"
                             :current-page="listDirectorParams.current"
                             layout="total, prev, pager, next, jumper"
                             :page-size="8"
                             :total="directorTotal">
              </el-pagination>
              <el-pagination v-if="(index==3 && isShowGoods===false && i.member.length>0) || (index==3 && isShowGoods && i.member.length>0  && i.member[0].goodsList && i.member[0].goodsList.row && i.member[0].goodsList.row.length>0)"
                             @current-change="memberHandleCurrentChange"
                             :current-page="listMemberParams.current"
                             layout="total, prev, pager, next, jumper"
                             :page-size="8"
                             :total="memberTotal">
              </el-pagination>
            </div>

          </div>



<!--
          <div v-for="(item1, index) in rows" :key="index">
            <template v-if="(isShowGoods===false)||(isShowGoods && isShowCard(item1))">
              <TitleCard :title="item1.level" style="max-width: 97%; margin: 10px"
                         v-if="item1.member.length !==0"></TitleCard>
              <div class="card-body" style="padding-top: 0px">
                <div class="row g-6 mb-5 g-xl-9 mb-xl-1">
                  &lt;!&ndash;begin::Followers&ndash;&gt;
                  &lt;!&ndash;begin::Col&ndash;&gt;
                  <div :class="{'col-md-12': isShowGoods,'col-md-3':!isShowGoods,'d-flex':true}"
                       v-for="(item, index) in item1.member" :key="index">
                    &lt;!&ndash;begin::Card&ndash;&gt;
                    <div :class="{'col-md-3':isShowGoods,'card':true}" style="min-width: 270.5px">
                      &lt;!&ndash;begin::Card body&ndash;&gt;
                      <div class="card-body d-flex flex-center flex-column py-9 px-5">
                        &lt;!&ndash;begin::Avatar&ndash;&gt;
                        <div v-if="item.isNew" class="rightTopTag">
                          <span style="margin-left: 15px">新</span>
                        </div>
                        <div class="symbol symbol-65px symbol-circle mb-5">
                          <img :src="item.logo" alt="image"/>


                        </div>

                        &lt;!&ndash;end::Avatar&ndash;&gt;
                        &lt;!&ndash;begin::Name&ndash;&gt;
                        <a href="#" class="fs-4 text-gray-800 text-hover-primary fw-bold mb-0">{{ item.name }}</a>
                        &lt;!&ndash;end::Name&ndash;&gt;
                        &lt;!&ndash;begin::Position&ndash;&gt;
                        <div class="fw-semibold text-gray-400 mb-6" :title="'主营品牌：'+item.category" style="overflow: hidden;
    white-space: nowrap;width: 80%;text-overflow: ellipsis;">主营品牌：{{ item.category }}
                        </div>
                        &lt;!&ndash;end::Position&ndash;&gt;
                        &lt;!&ndash;begin::Info&ndash;&gt;
                        <div class="d-flex flex-center flex-wrap mb-5">
                          &lt;!&ndash;begin::Stats&ndash;&gt;
                          <div v-if="item.goodsList.list ===null || item.goodsList.list.length === 0"
                               class="border border-dashed rounded min-w-90px py-3 px-4 mx-2 mb-3">
                            <div class="fs-6 fw-bold text-gray-700 text-center">0</div>
                            <div class="fw-semibold text-gray-400 text-center">商品数量</div>
                          </div>
                          <template v-else>
                            <div v-for="(i,a) in item.goodsList.list"
                                 :key="a" class="border border-dashed rounded min-w-90px py-3 px-4 mx-2 mb-3">
                              <div class="fs-6 fw-bold text-gray-700 text-center">{{ i.count }}</div>
                              <div class="fw-semibold text-gray-400 text-center">{{ i.title }}数量</div>
                            </div>
                          </template>
                        </div>
                        &lt;!&ndash;end::Info&ndash;&gt;
                        &lt;!&ndash;begin::Follow&ndash;&gt;
                        <button class="btn btn-sm btn-primary btn-flex btn-center" data-kt-follow-btn="true"
                                @click="openInfo(item)">
                          <span class="indicator-label">查看详情</span>
                        </button>
                        &lt;!&ndash;end::Follow&ndash;&gt;
                      </div>
                      &lt;!&ndash;begin::Card body&ndash;&gt;
                    </div>

                    <div class="card col-md-9" style="margin-left: 10px" v-if="isShowGoods">

                      <vip-goods @openBuyOneFromGoods="openBuyOneFromGoods" :globalListParams="globalListParams" :queryParam="queryParam" @openBuyManyFromGoods="openBuyManyFromGoods" @openGoodInfo="openGoodInfo" :staffId="item.staffId"></vip-goods>
                    </div>
                    &lt;!&ndash;begin::Card&ndash;&gt;
                  </div>
                </div>
              </div>

              <div style="display: flex;justify-content: center" v-if="index != 0">
                <el-pagination v-if="index == 1 && item1.member.length !=0"
                               @current-change="vicePresidentHandleCurrentChange"
                               :current-page="listVicePresidentParams.current"
                               layout="total, prev, pager, next, jumper"
                               :total="vicePresidentTotal">
                </el-pagination>
                <el-pagination v-if="index == 2 && item1.member.length !=0"
                               @current-change="directorHandleCurrentChange"
                               :current-page="listDirectorParams.current"
                               layout="total, prev, pager, next, jumper"
                               :total="directorTotal">
                </el-pagination>
              </div>

            </template>
          </div>
-->

        </div>
      </div>
    </div>
    <vip-info :item="item"  ref="vipInfo" :goods-list="goodsList" :vipDetailVisible="dialogVisible"
              @closeDialog="closeVipInfoDialog"></vip-info>
    <buy-one :item="goodsItem" :buyOneVisible="buyOneVisibleFromGoods"
             @closeDialog="closeBuyOneDialogFromGoods"></buy-one>
    <buy-many goods-staff-id=111 :buyManyVisible="buyManyVisibleFromGoods"
              @closeDialog="closeBuyManyDialogFromGoods"></buy-many>
    <el-dialog v-if="goodsDetailVisible" :visible.sync="goodsDetailVisible" appendToBody=true>
      <template #title>
        <div class="modal-content">
          <div class="modal-header">
            <h2>商品详情</h2>
          </div>
        </div>
      </template>
      <GoodInfoComponent ref="goodInfoComponent"></GoodInfoComponent>
    </el-dialog>
  </div>
</template>
<script>
//通讯录
import {addressBook} from "@/views/VipUser/component/addressBook";
import vipInfo from "@/components/vipInfo/index.vue";
import buyMany from "@/components/buyMany/index.vue";
import buyOne from "@/components/buyOne/index.vue";
import GoodInfoComponent from "@/components/GoodInfoComponent/index.vue";
import VipGoods from "./vip-goods.vue";

export default {
  name: "addressBook",
  components: {VipGoods, GoodInfoComponent, buyOne, buyMany, vipInfo},
  mixins: [addressBook]

}
</script>
<style scoped>
.cardItem {
  width: 18.5%;
  padding: 10px 15px;
  background: #fff;
  margin-right: 20px;
  margin-top: 15px;
  border-radius: 10px;
}

.flex_no {
  display: flex;
  align-items: center;
}

.firmIcon {
  width: 12%;
  aspect-ratio: 1 / 1;
}

.firmName {
  max-width: 86%;
  margin-left: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 15px;
  font-weight: 600;
}

.rightTopTag {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #ff6347; /* 设置背景颜色 */
  color: white;
  text-align: center;
  line-height: 30px;
  border-top-right-radius: 15px;
  width: 60px;
  height: 60px;
  clip-path: polygon(0 0, 100% 0, 100% 100%);
  font-size: 20px;
}
</style>
