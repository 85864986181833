import {getCategoryList} from "@/api/categoryApi";
import {vipAddressBookGetList, vipAddressBookSetCheckEnterpriseApi} from "@/api/vipAddressBookApi";

export const addressBook = {
    data() {
        return {
            buyManyVisibleFromGoods: false,
            buyOneVisibleFromGoods: false,
            dialogVisible: false,
            goodsDetailVisible: false,
            myGoodsList: [],
            typeIndex: 0,
            goodsList: [],
            item: {},
            levelArr: ["省级展馆", "云南馆", "直辖市", "海外馆"],
            rows: [
                {
                    level: "省级展馆",
                    member: []
                },
                {
                    level: "云南馆",
                    member: []
                },
                {
                    level: "直辖市",
                    member: []
                },
                {
                    level: "海外馆",
                    member: []
                }


            ],
            categoryOne: [],
            categoryTwo: [],
            loading: false,
            params: {
                title: "",
                phone: "",
                skuName: "",
            },
            queryParam: {
                categoryId1: null,
                categoryId2: null
            },
            goodsItem: {},
            isShowGoods: false,

            listParams: {
                region: 1,
                current: 1,
                size: 12,
            },
            // 副会长单位 查询调教
            listVicePresidentParams: {
                region: 2,
                current: 1,
                size: 8,
            },
            listDirectorParams: {
                region: 3,
                current: 1,
                size: 8,
            },
            listMemberParams: {
                region: 4,
                current: 1,
                size: 8,
            },
            globalListParams: {
                enterpriseName: "",
                goodsName: "",
                categoryOne: "",
                categoryTwo: "",
            },
            vicePresidentTotal: 0,
            directorTotal: 0,
            memberTotal: 0,
        }
    },

    methods: {
        openInfo(item) {
            this.dialogVisible = true
            this.item = item
            this.$refs.vipInfo.initList(item)
            this.setCheckEnterprise(item)
            item.isNew = false

        },
        shaixuan() {
            this.rows = [{
                level: "会长及常务单位",
                member: []
            },
                {
                    level: "副会长单位",
                    member: []
                },
                {
                    level: "理事单位",
                    member: []
                },
                {
                    level: "会员单位",
                    member: []
                }]
            if ((this.globalListParams.goodsName != null && this.globalListParams.goodsName != "")
                || (this.queryParam.categoryId1 != "null" && this.queryParam.categoryId1 != null)) {
                this.isShowGoods = true
            } else {
                this.isShowGoods = false
            }
            if (this.queryParam.categoryId1 != "null" && this.queryParam.categoryId1 != null) {
                this.globalListParams.categoryOne = this.queryParam.categoryId1
            } else {
                this.globalListParams.categoryOne = ""
            }
            if (this.queryParam.categoryId2 != "null" && this.queryParam.categoryId1 != null) {
                this.globalListParams.categoryTwo = this.queryParam.categoryId2
            } else {
                this.globalListParams.categoryTwo = ""
            }
            this.refreshList()
        },
        resetQuery() {
            this.isShowGoods = false
            this.globalListParams = {
                enterpriseName: "",
                goodsName: "",
                categoryOne: "",
                categoryTwo: "",
            }
            this.queryParam.categoryId1 = null
            this.queryParam.categoryId2 = null
            this.listParams.current = 1
            this.listDirectorParams.current = 1
            this.listVicePresidentParams.current = 1
            this.refreshList()
            this.typeIndex++;
        },
        changIsJd(type) {
            this.params.isJd = type;
        },
        closeVipInfoDialog() {
            this.dialogVisible = false
        },
        openBuyManyFromGoods() {
            this.buyManyVisibleFromGoods = true
        },

        closeBuyManyDialogFromGoods() {
            this.buyManyVisibleFromGoods = false
        },
        openBuyOneFromGoods(item) {
            this.buyOneVisibleFromGoods = true
            this.goodsItem = item
        },
        closeBuyOneDialogFromGoods() {
            this.buyOneVisibleFromGoods = false
        },
        //初始化分类
        async getCategory1List(parentId) {
            const {data} = await getCategoryList({parentId});
            this.categoryOne = data.list
        },
        async changeCategoryId1() {
            const categoryId1 = this.queryParam.categoryId1;
            console.log(categoryId1)
            this.queryParam.categoryId2 = null
            if (categoryId1 == "null") {
                this.categoryTwo = []
            } else {
                const param = {parentId: categoryId1};
                const {data} = await getCategoryList(param);
                this.categoryTwo = data.list
            }
        },
        handleCurrentChange(val, item) {
            item.current = val
        },
        vicePresidentHandleCurrentChange(val) {
            this.listVicePresidentParams.current = val
            this.getVicePresidentList()
        },
        directorHandleCurrentChange(val) {
            this.listDirectorParams.current = val
            this.getDirectorList()
        },
        memberHandleCurrentChange(val) {
            this.listMemberParams.current = val
            this.getMemberList()
        },
        openGoodInfo(id, isJd) {
            this.goodsDetailVisible = true
            //this.$refs.goodInfo.open()
            setTimeout(() => {
                this.$refs.goodInfoComponent.openGoodsInfo(id, isJd);
            }, 200)
        },
        async getPresidentList() {
            this.loading = true
            let params = {
                ...this.globalListParams,
                ...this.listParams
            }
            const {data} = await vipAddressBookGetList(params);
            this.rows[0].member = data.rows
            this.loading = false
        },
        async getVicePresidentList() {
            this.loading = true
            let params = {
                ...this.globalListParams,
                ...this.listVicePresidentParams
            }
            const {data} = await vipAddressBookGetList(params);
            this.rows[1].member = data.rows
            this.vicePresidentTotal = data.total
            this.loading = false
        },
        async getDirectorList() {
            this.loading = true
            let params = {
                ...this.globalListParams,
                ...this.listDirectorParams
            }
            const {data} = await vipAddressBookGetList(params);
            this.rows[2].member = data.rows
            this.directorTotal = data.total
            this.loading = false
        },
        async getMemberList() {
            this.loading = true
            let params = {
                ...this.globalListParams,
                ...this.listMemberParams
            }
            const {data} = await vipAddressBookGetList(params);
            this.rows[3].member = data.rows
            this.memberTotal = data.total
            this.loading = false
        },
        async refreshList() {
            this.loading = true
            this.getPresidentList()
            this.getVicePresidentList()
            this.getDirectorList()
            await this.getMemberList()
            this.loading = false
        },
        async setCheckEnterprise(item) {
            if (item.isNew) {
                let {data} = await vipAddressBookSetCheckEnterpriseApi({staffId: item.staffId})
                console.log(data)
            }
        },
        isShowCard(item) {
            for (let i = 0; i < item.member.length; i++) {
                let e = item.member[i];
                return e.goodsList.row.length == 0 ? false : true;
            }
        }


    },
    created() {
        this.refreshList()
        this.getCategory1List(0)
    }
}
